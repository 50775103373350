import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <div className="container w-full md:max-w-3xl mx-auto pt-20">
        <div classNme="w-full">
          <h2 classNme="text-center font-sans text-gray-400 text-md font-bold uppercase">
            Resume
          </h2>
          <hr classNme="border-b-2 border-gray-300 mx-4" />
          <div classNme="container px-4">
            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4 ">
                <h3 classNme="text-gray-700 mb-2 text-xl font-serif ">
                  <span classNme="font-bold text-gray-900">
                    Commercial Director
                  </span>{" "}
                  at <span classNme="">Agiledrop</span>
                </h3>
                <div classNme="mb-2 text-gray-700 text-sm">
                  Nov 2013 – Present
                </div>
                <p>
                  Developing and implementing commercial strategies aiming to
                  accelerate growth. Responsible for marketing, sales and
                  account management. Apart from commercial activities, also
                  responsible for talent acquisition marketing.
                  <br />
                  <br />
                  Accomplishments:
                  <br />
                  📈 1000% growth in revenue in the last 4 years
                  <br />
                  📈 from 3 to 60 full-time employees in 6 years
                </p>
              </div>
            </div>

            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4 ">
                <h3 classNme="text-gray-700 mb-2 text-xl font-serif ">
                  <span classNme="font-bold text-gray-900">
                    Commercial Director
                  </span>{" "}
                  at <span classNme="">Agiledrop</span>
                </h3>
                <div classNme="mb-2 text-gray-700 text-sm">
                  Nov 2013 – Present
                </div>
                <p>
                  Developing and implementing commercial strategies aiming to
                  accelerate growth. Responsible for marketing, sales and
                  account management. Apart from commercial activities, also
                  responsible for talent acquisition marketing.
                  <br />
                  <br />
                  Accomplishments:
                  <br />
                  📈 1000% growth in revenue in the last 4 years
                  <br />
                  📈 from 3 to 60 full-time employees in 6 years
                </p>
              </div>
            </div>

            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4 ">
                <h3 classNme="text-gray-700 mb-2 text-xl font-serif ">
                  <span classNme="font-bold text-gray-900">
                    Commercial Director
                  </span>{" "}
                  at <span classNme="">Agiledrop</span>
                </h3>
                <div classNme="mb-2 text-gray-700 text-sm">
                  Nov 2013 – Present
                </div>
                <p>
                  Developing and implementing commercial strategies aiming to
                  accelerate growth. Responsible for marketing, sales and
                  account management. Apart from commercial activities, also
                  responsible for talent acquisition marketing.
                  <br />
                  <br />
                  Accomplishments:
                  <br />
                  📈 1000% growth in revenue in the last 4 years
                  <br />
                  📈 from 3 to 60 full-time employees in 6 years
                </p>
              </div>
            </div>

            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4">
                <p classNme="text-gray-700 text-base">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptatibus quia, nulla! Maiores et perferendis eaque,
                  exercitationem praesentium nihil.
                </p>
              </div>
            </div>

            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4">
                <p classNme="text-gray-700 text-base">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptatibus quia, nulla! Maiores et perferendis eaque,
                  exercitationem praesentium nihil.
                </p>
              </div>
            </div>

            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4">
                <p classNme="text-gray-700 text-base">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptatibus quia, nulla! Maiores et perferendis eaque,
                  exercitationem praesentium nihil.
                </p>
              </div>
            </div>

            <div classNme="w-full rounded overflow-hidden shadow-lg flex my-8 bg-white">
              <div classNme="px-6 py-4">
                <p classNme="text-gray-700 text-base">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptatibus quia, nulla! Maiores et perferendis eaque,
                  exercitationem praesentium nihil.
                </p>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
